import { createContext, useContext, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
  CookieStorage
} from "amazon-cognito-identity-js";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  console.log("Constructing AuthProvider");
  const [user, setUser] = useLocalStorage("user", null);
  const navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = useCallback(
    async (data) => {
      //setUser(data);
      //navigate("/protected");      

      const cognitoUser = new CognitoUser({
        Username: "brad@bradwelborn.com",
        Pool: new CognitoUserPool({
          UserPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
          ClientId: process.env.REACT_APP_AWS_CLIENTID,
          Storage: new CookieStorage({domain: "localhost"})
        }),
        Storage: new CookieStorage({domain: "localhost"})
      });

      cognitoUser.authenticateUser(
        new AuthenticationDetails({
          Username: "brad@bradwelborn.com",
          Password: "Nsdfs!3454353",
        }),
        {
          onSuccess: function (result) {
            setUser(result.getAccessToken().getJwtToken());
            console.log("Login successful");
            navigate("/protected");            
          },

          onFailure: function (err) {
            alert(err.message || JSON.stringify(err));
          },
        }
      );
    },
    [setUser, navigate]
  );

  // call this function to sign out logged in user
  const logout = useCallback(() => {
    setUser(null);
    navigate("/", { replace: true });
  }, [setUser, navigate]);

  const value = useMemo(
    () => ({
      user,
      login,
      logout,
    }),
    [user, login, logout]
  );
  console.log("Finished Creating Auth provider");
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
