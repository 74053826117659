import { useAuth } from "../hooks/useAuth";

function ProtectedPage() {
  const authCtx = useAuth();
  const clientId = process.env.REACT_APP_AWS_CLIENTID;
  console.log(clientId);

  const logoutClickHandler = (event) => {
    event.preventDefault();
    console.log("token cleared");
    authCtx.logout();
  };

  return (
    <div>
      <p style={{ fontSize: "22px" }}>Protected</p>
      <button style={{ fontSize: "18px" }} onClick={logoutClickHandler}>
        Logout
      </button>
      <p>{clientId}</p>
      <p>It is the new page V3</p>
      <p>{process.env.REACT_APP_DOMAIN}</p>
    </div>
  );
}

export default ProtectedPage;
