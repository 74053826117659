import "./LoginForm.css";
import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth";

const LoginForm = () => {
  const [isPasswordForgotten, setIsPasswordForgotten] = useState('KNOWN');
  const authCtx = useAuth();

  const ForgotPasswordClickHandler = (event) => {
    event.preventDefault();
    console.log("Forgot Password Click");
    setIsPasswordForgotten('FORGOTTEN');
  };

  const LoginClickHandler = (event) => {
    event.preventDefault();
    console.log("Login Click");
    authCtx.login('Brad');
  };

  const ResetPasswordSubmitHandler = (event) => {
    event.preventDefault();
    console.log("ResetPasswordSubmitHandler clicked");
    setIsPasswordForgotten('RESET');
  };

  const CancelForgotPasswordClickHandler = (event) => {
    event.preventDefault();
    console.log("Cancel Forgot Password Click");
    setIsPasswordForgotten('KNOWN');
  }

  const BackToLoginClickHandler = (event) => {
    event.preventDefault();
    console.log("Back to Login Click");
    setIsPasswordForgotten('KNOWN');
  }

  const loginForm = (
    <form onSubmit={LoginClickHandler}>
      <label htmlFor="email-address">Email Address</label>
      <input type="email" id="email-address" required />
      <label htmlFor="password">Password</label>
      <input type="password" id="password" minLength='6' required />
      <label className="check-label">
        <input type="checkbox" />
        Remember Me
      </label>
      <button type="submit" className="sendBtn">
        Login
      </button>
      <button
        type="button"
        onClick={ForgotPasswordClickHandler}
        className="forgot-password"
      >
        Forgot your password?
      </button>
    </form>
  );

  const forgotPasswordForm = (
    <form onSubmit={ResetPasswordSubmitHandler}>
      <label htmlFor="email-address">Email Address</label>
      <input type="email" id="email-address" required />
      <button type="submit" className="sendBtn">
        Reset Password
      </button>
      <button
        type="button"
        onClick={CancelForgotPasswordClickHandler}
        className="forgot-password"
      >
        Cancel
      </button>
    </form>
  );

  const passwordSent = (
    <div>
    <p className="message">An email has been sent with your new password.</p>
    <button type="button" className="sendBtn" onClick={BackToLoginClickHandler}>Back to Login</button>
    </div>
  );

  const renderSwitch = (state) => {
    switch(state) {
      case "FORGOTTEN":
        return forgotPasswordForm;
      case "RESET":
        return passwordSent;
      default:
        return loginForm;
    }
  }

  return (
    <div className="login-form">
      <h3>Prognose</h3>
      {renderSwitch(isPasswordForgotten)}    
      <hr />
      <p>&copy; Prognose All Rights Reserved 2022</p>
    </div>
  );
};

export default LoginForm;
