import LargeLogo from "../assets/PrognoseLogoLarge.png";
import LoginForm from "../components/LoginForm";
import "./LoginPage.css";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

function LoginPage() {
  const { user } = useAuth();

  if (user) {
    console.log("Redirecting away from here");
    return <Navigate to="/protected" replace={true} />;
  }


  return (
    <div id="container">
      <div id="content">
        <div id="contentframe">
          <div id="imagebox">
            <img src={LargeLogo} alt="" />
          </div>
          <div id="footer">
            <h4>Same Application, a fresher look</h4>
            <p>
              The new Prognose application will be responsive across all
              browsers, mobile, tablets and many more. Keep information at your
              fingertips.
            </p>
          </div>
        </div>
      </div>
      <div id="sidebar">
        <LoginForm />
      </div>
    </div>
  );
}

export default LoginPage;
