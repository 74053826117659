import { Routes, Route, Navigate} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import ProtectedPage from "./pages/ProtectedPage";
import {ProtectedRoute} from "./components/ProtectedRoute";
import './App.css'; 


const App = () => {  
  
  return (
    <div id='App'>
    <Routes>
      <Route path="/" element={<LoginPage />} />      
      <Route
        path="/protected"
        element={
          <ProtectedRoute>
            <ProtectedPage />
          </ProtectedRoute>
        }
      />   
      <Route
        path="*"
        element={<Navigate to="/" replace />}
    />
    </Routes>
    </div>
  );
}

export default App;
